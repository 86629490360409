import { TableCell, TableRow, Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import { RoleGuard } from 'component/base/RoleGuard';

const LoadingSkeleton: React.FC = () => {
  const rows = () =>
    Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={`skeleton-${i}`}>
        <TableCell width="40%">
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <RoleGuard roles={['billing_admin']} type="block">
          <TableCell width="15%">
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Skeleton width={36} />
              </Grid>
              <Grid item>
                <Skeleton width={36} />
              </Grid>
              <Grid item>
                <Skeleton width={36} />
              </Grid>
            </Grid>
          </TableCell>
        </RoleGuard>
      </TableRow>
    ));

  return <>{rows()}</>;
};

export default LoadingSkeleton;
