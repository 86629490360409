import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { Rating } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { NewPlugin, useInstallPlugin } from 'api/plugins';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import parse from 'html-react-parser';
import { isInIframe } from 'utils/iframe';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { useTranslation } from 'react-i18next';
import { RoleGuard } from 'component/base/RoleGuard';

const PREFIX = 'PluginCard';

const classes = {
  image: `${PREFIX}Image`,
  typographyTitle: `${PREFIX}TypographyTitle`,
  typographyInstalls: `${PREFIX}TypographyInstalls`,
  typographyDescription: `${PREFIX}TypographyDescription`,
  box: `${PREFIX}Box`,
  rating: `${PREFIX}Rating`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.image}`]: {
    display: 'block',
    width: '100%',
  },
  [`& .${classes.typographyTitle}`]: {
    fontWeight: 600,
    marginBottom: '0.3125rem',
    paddingRight: '2.25rem',
    position: 'relative',
    '& button': {
      position: 'absolute',
      right: '0',
      top: '-0.5rem',
    },
  },
  [`& .${classes.typographyInstalls}`]: {
    marginTop: '0.3125rem',
  },
  [`& .${classes.typographyDescription}`]: {
    margin: '0.625rem 0 1rem 0',
  },
  [`& .${classes.box}`]: {
    alignItems: 'center',
    display: 'flex',
  },
  [`& .${classes.rating}`]: {
    fontSize: '0.75rem',
    marginRight: '0.3125rem',
  },
});

interface PluginCardProps extends NewPlugin {
  readonly setInstallName: (name: string) => void;
  readonly installingPlugin: string;
  readonly isInstalled: boolean;
  readonly isSyncing: boolean;
}

const PluginCard: FC<PluginCardProps> = ({
  icons,
  name,
  short_description,
  homepage,
  rating,
  num_ratings,
  active_installs,
  slug,
  installingPlugin,
  setInstallName,
  isInstalled,
  isSyncing,
}) => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { sendMessage } = useContext(PostMessageContext);
  const { t } = useTranslation();

  const handleUrlClick = () => {
    if (isInIframe()) {
      sendMessage(
        JSON.stringify({
          type: 'externalLink',
          data: {
            link: homepage,
          },
        })
      );
    } else {
      window.open(homepage);
    }
  };

  const { isPending, mutateAsync } = useInstallPlugin(siteId);

  const handleInstallPlugin = async () => {
    setInstallName(slug || '');
    await mutateAsync({
      plugins: slug,
      activate: true,
    });
  };

  const iconSrc = icons?.['1x'];

  const displayLoadingButton = () => {
    if (isPending) {
      return true;
    }
    if (isSyncing && installingPlugin === slug && !isInstalled) {
      return true;
    }
    return false;
  };

  return (
    <StyledGrid item xs={12} sm={6} md={4}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <img src={iconSrc} alt={name} className={classes.image} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h3" className={classes.typographyTitle}>
            {parse(name || '')}
            {homepage && homepage !== '' ? (
              <IconButton onClick={handleUrlClick} color="primary" size="large">
                <Launch />
              </IconButton>
            ) : null}
          </Typography>
          <Box className={classes.box}>
            <Rating readOnly value={Math.ceil(rating! / 20)} className={classes.rating} />
            <Typography variant="body2">
              {num_ratings} {t('ratings')}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.typographyInstalls}>
            {t('active_install', { count: active_installs })}
          </Typography>
          <Typography className={classes.typographyDescription}>
            {parse(short_description!)}
          </Typography>
          <RoleGuard roles={['billing_admin']} type="block">
            <ProgressiveButton
              text={isInstalled ? t('installed') : t('install_plugin')}
              disabled={displayLoadingButton() || isInstalled}
              isLoading={displayLoadingButton()}
              variant="outlined"
              onClick={handleInstallPlugin}
            />
          </RoleGuard>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default PluginCard;
