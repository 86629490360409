import { useContext, useState } from 'react';
import ContentViewCard from 'component/base/ContentViewCard';

import PaginationControls from 'component/base/PaginationControls';
import {
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { CreateBackupRequest, useCreateBackup, useDeleteBackup, useGetBackups } from 'api/backups';
import { useParams } from 'react-router';
import LoadingSkeleton from './components/LoadingSkeleton';
import { SearchRounded } from '@mui/icons-material';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useSnackbar } from 'component/hooks/useSnackbar';
import CreateBackup from './components/CreateBackup';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import BackupList from './components/BackupList';
import { AutomatedBackups } from './components/AutomatedBackups';
import { SiteDetail } from '../../../../api/site';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';
import { TaskNotificationContext } from 'component/root/TaskNotificationProvider';
import { RoleGuard } from 'component/base/RoleGuard';

interface RestoreModal extends ConfirmationModal {
  onSubmit: () => Promise<unknown>;
}

export interface ConfirmationModal {
  open: boolean;
  backupId: null | number;
  type: 'delete' | 'restore';
}

const initConfirmation: ConfirmationModal = {
  open: false,
  backupId: null,
  type: 'delete',
};

const BackupPage = ({ siteDetails }: { readonly siteDetails?: SiteDetail }) => {
  const { t } = useTranslation();
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const { startWatchingTasks } = useContext(TaskNotificationContext);

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal | RestoreModal>(
    initConfirmation
  );
  const { mutateAsync: deleteBackup, isPending: deleteLoading } = useDeleteBackup(siteId);
  const handleDeleteBackup = async () => {
    if (confirmationModal.backupId) {
      let snackMessage: string = t('backup_deleted_successfully');
      let snackType: 'success' | 'error' = 'success';
      try {
        await deleteBackup(confirmationModal.backupId);
        setConfirmationModal(initConfirmation);
      } catch (err) {
        snackMessage = t('backup_action_failed', { type: t('backup_label_delete').toLowerCase() });
        snackType = 'error';
      } finally {
        enqueueSnackbar(snackMessage, {
          key: 'errorCreate',
          variant: snackType,
        });
      }
    }
  };

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { mutateAsync, isPending: createLoading } = useCreateBackup(siteId);

  const tableState = usePaginatedListState({ urlStatePrefix: 'manual' });
  const { data, isLoading } = useGetBackups(siteId, tableState);
  const loadingState = usePaginatedListLoadingState(
    !!data?.data.result?.length,
    isLoading,
    tableState.params
  );

  const staticSite: boolean = siteDetails?.site_type === 1 || false;

  const submit = async (postData: CreateBackupRequest) => {
    await mutateAsync(postData);
    startWatchingTasks();
    setConfirmModal(false);
  };

  const closeModal = () => setConfirmModal(false);

  const confirmRestore = async () => {
    await (confirmationModal as RestoreModal).onSubmit();
    startWatchingTasks();
    setConfirmationModal(initConfirmation);
  };

  const renderConfirmationDescription = () => {
    if (['delete', 'restore'].includes(confirmationModal.type)) {
      return t('backup_short_confirmation_description', { type: confirmationModal.type });
    }
    return '';
  };

  return (
    <>
      {!staticSite ? (
        <ContentViewCard title={<Typography variant="h2">{t('backup_card_title')}</Typography>}>
          <>
            <Grid spacing={1} container direction="row" justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={e => tableState.setSearch(e.target.value)}
                  variant="outlined"
                  placeholder={t('backup_search_label')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disableTypography component="button">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <RoleGuard roles={['billing_admin']} type="block">
                <Grid item xs={12} sm="auto">
                  <Box>
                    <Button
                      onClick={() => setConfirmModal(true)}
                      variant="contained"
                      color="primary"
                    >
                      {t('backup_create_header')}
                    </Button>
                  </Box>
                </Grid>
              </RoleGuard>
            </Grid>
            <TableContainer>
              <Table aria-label={'backups table'}>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">{t('label')}</TableCell>
                    <TableCell>{t('backup_table_status')}</TableCell>
                    <TableCell>{t('created')}</TableCell>
                    <RoleGuard roles={['billing_admin']} type="block">
                      <TableCell>{t('actions')}</TableCell>
                    </RoleGuard>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {isLoading ? (
                    <LoadingSkeleton />
                  ) : loadingState === 'noData' ? (
                    <NoResultsFound colSpan={4} />
                  ) : (
                    <BackupList
                      setConfirmationModal={setConfirmationModal}
                      list={data?.data.result}
                      modalData={confirmationModal}
                      siteId={siteId}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {!isLoading && (
              <PaginationControls
                totalRowCount={data?.data.metadata?.total || 0}
                perPage={tableState.params.perPage}
                onPageChange={page => tableState.setPage(page)}
                onPerPageChange={perPage => tableState.setPerPage(perPage)}
              />
            )}
          </>
        </ContentViewCard>
      ) : null}
      <AutomatedBackups siteId={siteId} />

      <CreateBackup
        isLoading={createLoading}
        onSubmit={submit}
        open={confirmModal}
        onClose={closeModal}
      />

      <ConfirmationDialog
        action={confirmationModal.type === 'restore' ? 'confirm' : 'delete'}
        forceLoadingState={Boolean(deleteLoading)}
        onClose={() => setConfirmationModal(initConfirmation)}
        open={confirmationModal.open}
        onConfirm={confirmationModal.type === 'restore' ? confirmRestore : handleDeleteBackup}
        title={t('backup_tooltip', {
          type:
            confirmationModal.type === 'delete'
              ? t('backup_label_delete')
              : t('backup_label_restore'),
        })}
        description={renderConfirmationDescription()}
      />
    </>
  );
};

export default BackupPage;
