import { TableRow, TableCell, Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import { RoleGuard } from 'component/base/RoleGuard';

export default function InstalledThemeRowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <RoleGuard roles={['billing_admin']} type="block">
          <TableCell>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Skeleton width={36} />
              </Grid>
              <Grid item>
                <Skeleton width={36} />
              </Grid>
              <Grid item>
                <Skeleton width={36} />
              </Grid>
            </Grid>
          </TableCell>
        </RoleGuard>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}
