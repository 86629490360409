import { Card, CardContent, Stack, Typography } from '@mui/material';
import { getSiteWpStatus, SiteDetail } from 'api/site';
import { useTranslation } from 'react-i18next';

interface SiteStatusProps {
  readonly siteDetails?: SiteDetail;
}

export const SiteStatus = ({ siteDetails }: SiteStatusProps) => {
  const { t } = useTranslation();

  const wpStatus = getSiteWpStatus(siteDetails?.id, siteDetails && siteDetails.site_type !== 1);

  const data = wpStatus.data?.data?.result?.wordpress_status;

  const hasCriticalError = data?.operational_status === 'critical_error';
  const errorMessage = data?.error;

  return hasCriticalError ? (
    <Card sx={{ backgroundColor: '#fb3e3e' }}>
      <CardContent>
        <Stack gap={2}>
          <Typography variant="h3">{t('critical_error')}</Typography>
          <Typography variant="body1" sx={{ color: 'var(--color-mine-shaft)' }}>
            {t('wp_critical_error_description')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'var(--color-mine-shaft)' }}>
            {errorMessage}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  ) : null;
};
