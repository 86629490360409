import { useState, useRef, useEffect } from 'react';
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography,
  Button,
  InputAdornment,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { Skeleton } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { SiteDetail, SitePaginationState, useSites, useUserSites } from 'api/site';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'debounce';
import { cssVar } from 'utils/css';
import { formatDomainName } from 'utils/formatting';

export default function RowsSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

export function SiteSelectorMenu({
  siteDetails,
  isWpSiteControl,
}: {
  readonly siteDetails: SiteDetail | undefined;
  readonly isWpSiteControl: boolean;
}) {
  const [showSiteDropdown, setShowSiteDropdown] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<SitePaginationState>({
    perPage: 10,
    activePageNumber: 1,
    filter: '',
    sortAttr: 'domain',
    sortOrder: 'asc',
  });
  const sitesData = useSites(pagination, { enabled: showSiteDropdown && !isWpSiteControl });
  const userSitesData = useUserSites(pagination, { enabled: showSiteDropdown && isWpSiteControl });
  const { data, refetch, status } = isWpSiteControl ? userSitesData : sitesData;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    refetch();
  }, [pagination.filter]);

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowsSkeleton />;
    }

    return data?.data.result?.map(site => {
      return (
        <TableRow
          key={site.id}
          sx={{
            cursor: 'pointer',
            '& td': {
              color:
                siteDetails?.domain === site.domain
                  ? 'var(--color-curious-blue) !important'
                  : undefined,
            },
          }}
          onClick={() => {
            navigate(pathname.replace(String(siteDetails?.id ?? ''), String(site.id)));
          }}
        >
          <TableCell>{formatDomainName(site.domain)}</TableCell>
          <TableCell>{site.label}</TableCell>
          <TableCell>
            {siteDetails?.domain === site.domain ? <FontAwesomeIcon icon={faCheck} /> : null}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <Button
        sx={{
          margin: '0.3125rem 0',
          maxWidth: '100%',
          textAlign: 'left',
        }}
        aria-describedby="siteSelectorPopper"
        ref={anchorRef}
        onClick={() => {
          setShowSiteDropdown(!showSiteDropdown);
        }}
      >
        {
          <>
            <Typography
              variant="h1"
              data-testid="domainLabel"
              marginRight={1}
              textAlign="center"
              sx={{
                maxWidth: '28.125rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {formatDomainName(siteDetails?.domain)}
            </Typography>
            <Box
              sx={{
                display: 'inline',
                width: '1.125rem',
                marginRight: '1rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                paddingBottom: 0,
                '& svg': {
                  width: '0.875rem',
                  height: 'auto',
                  color: cssVar('--color-mine-shaft'),
                },
              }}
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </Box>
          </>
        }
      </Button>
      <Popper
        id="siteSelectorPopper"
        open={showSiteDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        style={{ zIndex: 1, width: '28.125rem', maxWidth: 'calc(100% - 2rem)' }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setShowSiteDropdown(false);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Box
                  padding={1}
                  sx={{
                    backgroundColor: cssVar('--color-ebb'),
                  }}
                >
                  <TextField
                    onChange={debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                      setPagination({
                        ...pagination,
                        activePageNumber: 1,
                        filter: event.target.value,
                      });
                    }, 700)}
                    variant="outlined"
                    placeholder={t('site_change_by_domain_label')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" disableTypography component="button">
                          <SearchRounded />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: cssVar('--color-white'),
                      },
                    }}
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{
                    overflowX: 'auto',
                  }}
                >
                  <Table aria-label="Site List Table">
                    <TableBody>
                      {pagination.filter !== '' && data?.data.result?.length === 0 ? (
                        <NoResultsFound colSpan={3} />
                      ) : (
                        renderTableRows()
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}
