import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Stack, Typography } from '@mui/material';
import {
  FeaturedPlugin,
  useDeletePlugin,
  useInstallPlugin,
  useListFeaturedPlugins,
} from 'api/plugins';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPluginIconSrcSet } from 'utils/plugins';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'component/hooks/useSnackbar';

export const FeaturedPluginsList = () => {
  const { t } = useTranslation();
  const { siteId } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const plugins = useListFeaturedPlugins(siteId!);

  const installPlugin = useInstallPlugin(siteId!);
  const uninstallPlugin = useDeletePlugin(siteId!);

  const [pluginInProgress, setPluginInProgress] = useState<string | null>(null);

  const handleInstallClick = (plugin: FeaturedPlugin) => async () => {
    setPluginInProgress(plugin.name!);
    try {
      if (plugin.status === 'not_installed') {
        await installPlugin.mutateAsync({
          plugins: plugin.name,
          activate: true,
        });
        enqueueSnackbar(t('plugin_installed_successfully'), { variant: 'success' });
      } else {
        await uninstallPlugin.mutateAsync({ plugins: plugin.name });
        enqueueSnackbar(t('plugin_uninstalled_successfully'), { variant: 'success' });
      }
    } finally {
      setPluginInProgress(null);
    }
  };

  if (plugins.data?.data.result?.length) {
    return (
      <Stack sx={{ gap: 3, mb: 3, minHeight: '100px' }}>
        {plugins.data.data.result.map(plugin => (
          <Card key={plugin.name} sx={{ m: '0 !important' }}>
            <Stack direction="row" gap={2} p={2}>
              <img
                alt={plugin.title}
                srcSet={getPluginIconSrcSet(plugin.icons)}
                width={60}
                height={60}
              />
              <Stack gap={0.5} minWidth={0}>
                <Typography
                  variant="caption"
                  textTransform="uppercase"
                  fontWeight={600}
                  color="grey.700"
                >
                  {t('featured_plugin')}
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  {plugin.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="grey.700"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {plugin.status === 'not_installed'
                    ? plugin.description
                    : plugin.uninstall_description}
                </Typography>
              </Stack>
              <LoadingButton
                sx={{ ml: 'auto', alignSelf: 'flex-start', flex: 'none' }}
                variant="contained"
                color="primary"
                loading={plugin.name === pluginInProgress}
                onClick={handleInstallClick(plugin)}
                startIcon={
                  <FontAwesomeIcon icon={plugin.status === 'not_installed' ? faPlus : faMinus} />
                }
              >
                {plugin.status === 'not_installed' ? t('install_plugin') : t('uninstall')}
              </LoadingButton>
            </Stack>
          </Card>
        ))}
      </Stack>
    );
  }
};
