import { useState } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
} from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import {
  useGetInstalledThemesOld,
  useUpgradeInstalledTheme,
  useChangeActiveStatus,
  useDeleteInstalledTheme,
  ActivateThemeRequest,
  DeleteThemeRequest,
  UpdateThemeRequest,
} from 'api/themes';
import { sortByKey } from 'utils/sort';
import InstalledThemeRow, { ShowModalData } from './InstalledThemeRow';
import InstalledThemeRowSkeleton from '../skeletons/InstalledThemeRow';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import ContentViewCard from 'component/base/ContentViewCard';
import { useTranslation } from 'react-i18next';
import PaginationControls from 'component/base/PaginationControls';
import paginate from 'utils/paginate';
import { loadDefaultPerPage } from 'utils/paginate';
import { components } from 'openapi-types';
import { RoleGuard } from 'component/base/RoleGuard';

interface InstalledThemesProps {
  readonly siteId: string;
}

const InstalledThemes = ({ siteId }: InstalledThemesProps) => {
  const [confirmModal, setConfirmModal] = useState<ShowModalData | null>(null);
  const [invertList, setInvertList] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  // pagination
  const [perPage, setPerPage] = useState(loadDefaultPerPage());
  const [activePageNumber, setActivePageNumber] = useState(1);

  const { data, status, refetch } = useGetInstalledThemesOld(siteId);
  const { status: updateStatus, mutateAsync: updateAsync } = useUpgradeInstalledTheme(siteId);
  const { status: activeStatus, mutateAsync: activeAsync } = useChangeActiveStatus(siteId);
  const { status: deleteStatus, mutateAsync: deleteAsync } = useDeleteInstalledTheme(siteId);
  const { t } = useTranslation();

  const isActivateModal = Boolean((confirmModal?.data as any)?.status !== undefined);
  const isUpdateModal = Boolean((confirmModal?.data as any)?.update !== undefined);

  const handleConfirmChange = async () => {
    if (!confirmModal) {
      return;
    }
    const { data } = confirmModal;
    setPageLoading(true);
    try {
      if (isUpdateModal) {
        await updateAsync(data as UpdateThemeRequest);
      }

      if (isActivateModal) {
        await activeAsync(data as ActivateThemeRequest);
      }

      if (!isUpdateModal && !isActivateModal) {
        await deleteAsync(data as DeleteThemeRequest);
      }

      await refetch();
      setConfirmModal(null);
    } catch (err) {
      // error
    } finally {
      setPageLoading(false);
    }
  };

  const modalData = () => {
    const type = (function () {
      if (isUpdateModal) {
        return t('update');
      }
      if (isActivateModal) {
        return (confirmModal?.data as components['schemas']['ActivateThemeRequest']).status ===
          'activate'
          ? t('activate')
          : t('deactivate');
      }
      return t('delete');
    })();

    const title = t('theme_action_title', { type });
    const desc = t('action_description', {
      type: type.toLowerCase(),
      title: confirmModal?.title,
    });

    return {
      title,
      desc,
      confirmButtonText: type,
    };
  };

  const isModalLoading =
    deleteStatus === 'pending' ||
    pageLoading ||
    activeStatus === 'pending' ||
    updateStatus === 'pending';

  const installedThemes = data?.data.result || [];
  const orderedInstalledThemes = sortByKey(installedThemes, 'title', invertList);
  const themeList = paginate(orderedInstalledThemes, perPage, activePageNumber);

  const installText = (function () {
    if (orderedInstalledThemes.length === 0 && status === 'pending') {
      return undefined;
    }
    if (orderedInstalledThemes.length === 0 && status !== 'pending') {
      return `| ${t('no_themes_installed')}`;
    }

    return `| ${t('theme_count', { count: orderedInstalledThemes?.length ?? 0 })}`;
  })();

  const handlePaginationChange = (newPage: number) => {
    setActivePageNumber(newPage);
    refetch();
  };

  const handlePerPage = (value: number) => {
    setPerPage(value);
  };

  return (
    <>
      <ContentViewCard
        title={
          <Typography variant="h2">
            {t('installed')}{' '}
            <Box component="span">{t('installedType', { type: installText ?? '' })}</Box>
          </Typography>
        }
      >
        <>
          <TableContainer>
            <Table aria-label="Installed Themes Table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box component="span">
                      {t('name')}
                      <Button onClick={() => setInvertList(!invertList)} color="primary">
                        {invertList ? <ArrowDropUp /> : <ArrowDropDown />}
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>{t('description')}</TableCell>
                  <TableCell>{t('version')}</TableCell>
                  <TableCell>{t('status')}</TableCell>
                  <RoleGuard roles={['billing_admin']} type="block">
                    <TableCell width={172}>{t('action')}</TableCell>
                  </RoleGuard>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === 'pending' ? (
                  <InstalledThemeRowSkeleton />
                ) : (
                  themeList.map(theme => (
                    <InstalledThemeRow
                      setConfirmModal={setConfirmModal}
                      theme={theme}
                      key={theme.name}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            totalRowCount={orderedInstalledThemes.length}
            disabled={status === 'pending'}
            perPage={perPage}
            onPageChange={handlePaginationChange}
            onPerPageChange={handlePerPage}
          />
        </>
      </ContentViewCard>

      <ConfirmationDialog
        action={!isUpdateModal && !isActivateModal ? 'delete' : 'confirm'}
        forceLoadingState={Boolean(isModalLoading)}
        description={modalData().desc}
        onClose={() => setConfirmModal(null)}
        open={Boolean(confirmModal !== null)}
        onConfirm={handleConfirmChange}
        title={modalData().title || ''}
        confirmText={modalData().confirmButtonText}
      />
    </>
  );
};

export default InstalledThemes;
